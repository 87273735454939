import {
  Button,
  DatePicker,
  Form,
  notification,
  Select,
  Pagination,
  Empty,
  Input,
  Drawer,
  Row,
  Col,
  Switch,
} from "antd";
import locale from "antd/es/date-picker/locale/vi_VN";
import dayjs from "dayjs";
import React, { useEffect, useState, useRef } from "react";
import $axios from "../../services/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShuffle,
  faLocationDot,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import FilterBar from "../Common/FilterBar";
import ChooseCar from "../Common/ChooseCar";
import CarItem from "../Common/CarItem";
import CalendarCustom from "../Common/Calendar/CalendarCustom";
import "react-calendar/dist/Calendar.css";
import _ from "lodash";
const Booking = ({ setLoading, loading, setStep }) => {
  const [form] = Form.useForm();
  const [locations, setLocations] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [listTrip, setListTrip] = useState([]);
  const [totalTrip, setTotalTrip] = useState(0);
  const [area, setArea] = useState({});
  const [carSelected, setCarSelected] = useState(null);
  const [openChooseCar, setOpenChooseCar] = useState(false);
  const [searched, setSearched] = useState(false);
  const [valueCalendar, setValueCalendar] = useState(new Date());
  const [openCalendar, setOpenCalendar] = useState(false);
  const [resetFilter, setResetFilter] = useState(false);
  const [typeCalendar, setTypeCalendar] = useState("");
  const [minDateCalendar, setMinDateCalendar] = useState(new Date());
  const [tripType, setTripType] = useState("oneWay");
  const [filter, setFilter] = useState({
    addStart: "",
    addEnd: "",
    departDate: "",
    currentPage: 1,
    sort: "time:asc",
    fareMin: 0,
    fareMax: 2000000,
    ratingMin: 0,
    ratingMax: 5,
    timeMin: "00:00",
    timeMax: "23:59",
    availableSeatMin: 0,
    availableSeatMax: 50,
    onlineTicket: 1,
    // bộ lọc
    limousine: null,
    isPromotion: null,
    companies: [],
  });
  const [filterStatic, setFilterStatic] = useState(null);
  const [addPrice, setAddPrice] = useState(0);
  const [openDrawerChooseAdd, setOpenDrawerChooseAdd] = useState(false);
  const [nameField, setNameField] = useState("addStart");
  const [searchTerm, setSearchTerm] = useState("");
  const formRef = useRef(null);

  useEffect(() => {
    getAreaCity();
    getAddPrice();
  }, []);

  useEffect(() => {
    const prioritizedCities = ["Hà Nội", "Hồ Chí Minh"];
    const sortedProvinces = _.sortBy(
      _.filter(locations, (item) => {
        return (
          _.includes(_.toLower(item.name), _.toLower(searchTerm)) &&
          item.type === 3
        );
      }),
      (item) => {
        const index = prioritizedCities.indexOf(item.name);
        return index === -1 ? Infinity : index; // Nếu không trong danh sách ưu tiên, đặt sau cùng.
      }
    );
    setProvinces(sortedProvinces);
    setDistricts(
      _.filter(locations, (item) => {
        return (
          _.includes(_.toLower(item.name), _.toLower(searchTerm)) &&
          item.type !== 3
        );
      })
    );
  }, [searchTerm, locations]);

  const getAddPrice = async () => {
    try {
      const res = await $axios.$get(`/payment/getAddPrice`);
      if (res.data.data.data.status === 1) {
        setAddPrice(_.get(res, "data.data.data.amount", 0));
      }
    } catch (error) {
    } finally {
    }
  };

  const handleGetFilterStatic = async (_filter) => {
    try {
      const res = await $axios.$get(
        `/vexe/statistic-routes?from=${_filter.addStart}&to=${
          _filter.addEnd
        }&date=${dayjs(_filter.departDate).format("YYYY-MM-DD")}&onlineTicket=${
          _filter.onlineTicket
        }&sort=${_filter.sort}&page=${_filter.currentPage}&pageSize=20`
      );
      setFilterStatic(res.data.data);
    } catch (error) {
      notification.error({
        status: "error",
        message: "Thất bại",
        description: "Đã có lỗi xảy ra!",
      });
    } finally {
    }
  };

  const handleGetListTrip = async (values) => {
    setSearched(true);
    const _filter = {
      addStart: Number(values.addStart),
      addEnd: Number(values.addEnd),
      departDate: values.departDate,
      sort: "time:asc",
      currentPage: 1,
      fareMin: 0,
      fareMax: 2000000,
      ratingMin: 0,
      ratingMax: 5,
      timeMin: "00:00",
      timeMax: "23:59",
      availableSeatMin: 0,
      availableSeatMax: 50,
      onlineTicket: 1,
      limousine: null,
      isPromotion: null,
      companies: [],
    };
    if (tripType === "roundTrip") {
      _filter.returnDate = values.returnDate;
    }
    setResetFilter(true);
    setFilter(_filter);
    handleGetFilterStatic(_filter);
    handleFindRoute(_filter);
  };

  const handleSwap = () => {
    const addStart = form.getFieldValue("addStart");
    const addEnd = form.getFieldValue("addEnd");
    form.setFieldValue("addEnd", addStart);
    form.setFieldValue("addStart", addEnd);
  };

  const handleFindRoute = async (_filter) => {
    setLoading(true);
    try {
      let urlFilter = `/vexe/routes?from=${_filter.addStart}&to=${
        _filter.addEnd
      }&date=${dayjs(_filter.departDate).format("YYYY-MM-DD")}&onlineTicket=${
        _filter.onlineTicket
      }&ratingMin=${_filter.ratingMin}&ratingMax=${_filter.ratingMax}&timeMin=${
        _filter.timeMin
      }&timeMax=${_filter.timeMax}&fareMin=${_filter.fareMin}&fareMax=${
        _filter.fareMax
      }&availableSeatMin=${_filter.availableSeatMin}&availableSeatMax=${
        _filter.availableSeatMax
      }&sort=${_filter.sort}&page=${_filter.currentPage}&pageSize=20`;
      if (!_.isNil(_filter.limousine)) {
        urlFilter += `&limousine=${_filter.limousine}`;
      }
      if (!_.isNil(_filter.isPromotion)) {
        urlFilter += `&isPromotion=${_filter.isPromotion}`;
      }
      if (!_.isNil(_filter.companies && _filter.companies.length > 0)) {
        _.forEach(_filter.companies, (c) => {
          urlFilter += `&companies=${c}`;
        });
      }

      const res = await $axios.$get(urlFilter);
      setListTrip(res.data.data.data);
      setTotalTrip(res.data.data.total);
      setArea(res.data.data.area);
    } catch (error) {
      notification.error({
        status: "error",
        message: "Thất bại!",
        description: "Đã có lỗi xảy ra!",
      });
    } finally {
      setLoading(false);
    }
  };

  const getAreaCity = async () => {
    setLoading(true);
    try {
      const res = await $axios.$get(`/vexe/area/city_district`);
      setLocations(res.data.data);
      const prioritizedCities = ["Hà Nội", "Hồ Chí Minh"];
      const sortedProvinces = _.sortBy(
        _.filter(res.data.data, (item) => item.type === 3),
        (item) => {
          const index = prioritizedCities.indexOf(item.name);
          return index === -1 ? Infinity : index; // Nếu không trong danh sách ưu tiên, đặt sau cùng.
        }
      );
      setProvinces(sortedProvinces);
      setDistricts(_.filter(res.data.data, (item) => item.type !== 3));
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onOpenChange = (type) => {
    setValueCalendar(form.getFieldValue(type) ?? new Date());
    setOpenCalendar(true);
    setTypeCalendar(type);
    if (type === "returnDate") {
      setMinDateCalendar(new Date(form.getFieldValue("departDate")));
    } else {
      setMinDateCalendar(new Date());
    }
  };

  const setValue = (value) => {
    form.setFieldValue(typeCalendar, dayjs(value));
    setValueCalendar(value);
    setOpenCalendar(false);
  };

  const handleSelectAdd = (value) => {
    form.setFieldValue(nameField, value);
    setOpenDrawerChooseAdd(false);
    setSearchTerm("");
  };

  return (
    <div className="flex flex-col items-center mt-[0px] booking bg-white absolute left-1/2 transform -translate-x-1/2 w-[92%] top-[120px] rounded-[12px]">
      {/* w-[90%] mx-auto absolute top-[30px] left-1/2 transform -translate-x-1/2
      flex flex-col */}
      <CalendarCustom
        openCalendar={openCalendar}
        setOpenCalendar={setOpenCalendar}
        typeCalendar={typeCalendar}
        form={form}
        valueCalendar={valueCalendar}
        setValue={setValue}
        minDate={minDateCalendar}
      />
      <Form
        form={form}
        ref={formRef}
        onFinish={handleGetListTrip}
        layout="vertical"
        onFinishFailed={(values) => {
          console.log(values, "values");
        }}
        initialValues={{
          departDate: dayjs(),
        }}
        className="w-full"
      >
        <div
          style={{
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
          className="bg-white p-4 flex flex-col items-center rounded-[12px]"
        >
          {/* <div className="text-[18px] text-[#0064D2] font-semibold mb-0">
              Tìm xe
            </div>
            <Divider
              variant="dashed"
              style={{ borderColor: "#0064D2" }}
              className="my-4"
              dashed
            /> */}
          <Row
            gutter={0}
            className="items-center w-full mb-4"
            justify={"space-between"}
          >
            <Col span={11} style={{ paddingRight: 0 }}>
              <div>Điểm đi</div>
              <Form.Item
                name="addStart"
                rules={[
                  { required: true, message: "Điểm đi không được để trống!" },
                ]}
                className="w-full mb-0 no-required-mark"
              >
                <Select
                  showSearch={false}
                  open={false}
                  optionFilterProp="name"
                  placeholder="Điểm đi"
                  fieldNames={{ value: "id", label: "name" }}
                  className="h-12"
                  suffixIcon={
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      className="w-[14px]"
                      color="#0064D2"
                    />
                  }
                  // labelRender={(props) => (
                  //   <span className="text-[#22313F]">
                  //     {locations.find((item) => item.id === props.value)?.name ||
                  //       ""}
                  //   </span>
                  // )}
                  onClick={() => {
                    setOpenDrawerChooseAdd(true);
                    setNameField("addStart");
                  }}
                  options={locations}
                />
              </Form.Item>
            </Col>
            <Col span={2} className="flex items-center justify-center">
              <img
                src={"/images/swap.svg"}
                alt=""
                className="cursor-pointer mt-8"
                onClick={() => handleSwap()}
              />
            </Col>
            <Col span={11} style={{ paddingRight: 0 }}>
              <div>Điểm đến</div>
              <Form.Item
                name="addEnd"
                rules={[
                  {
                    required: true,
                    message: "Điểm đến không được để trống!",
                  },
                ]}
                className="w-full mb-0"
              >
                <Select
                  showSearch={false}
                  open={false}
                  optionFilterProp="name"
                  placeholder="Điểm đến"
                  fieldNames={{ value: "id", label: "name" }}
                  className="h-12"
                  suffixIcon={
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      className="w-[14px]"
                      color="#0064D2"
                    />
                  }
                  // labelRender={(props) => (
                  //   <span className="text-[#22313F]">
                  //     {locations.find((item) => item.id === props.value)?.name ||
                  //       ""}
                  //   </span>
                  // )}
                  onClick={() => {
                    setOpenDrawerChooseAdd(true);
                    setNameField("addEnd");
                  }}
                  options={locations}
                />
              </Form.Item>
            </Col>
          </Row>
          <div className="flex items-center justify-between w-full mb-2">
            <label className="font-medium text-[#22313F]">Ngày đi</label>
            <div className="flex items-center gap-2">
              <Switch
                checked={tripType === "roundTrip"}
                onChange={(checked) => {
                  setTripType(checked ? "roundTrip" : "oneWay");
                }}
              />{" "}
              <label className="font-medium text-[#22313F]">Khứ hồi</label>
            </div>
          </div>
          <Row
            gutter={0}
            className="items-center w-full mb-4"
            justify={"space-between"}
          >
            <Col
              span={tripType === "roundTrip" ? 12 : 24}
              style={{ paddingRight: tripType === "roundTrip" ? 14 : 0 }}
            >
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Vui lòng chọn ngày đi!",
                  },
                ]}
                name="departDate"
                className="w-full mb-4"
              >
                <DatePicker
                  className="w-full h-12"
                  placeholder="Chọn ngày"
                  format="DD/MM/YYYY"
                  minDate={dayjs()}
                  allowClear={false}
                  locale={locale} // Thiết lập locale tiếng Việt
                  suffixIcon={
                    <img src={"/images/date.svg"} className="w-[14px]" alt="" />
                  }
                  open={false}
                  onOpenChange={() => onOpenChange("departDate")}
                />
              </Form.Item>
            </Col>
            {tripType === "roundTrip" && (
              <Col span={12} style={{ paddingLeft: 14 }}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng chọn ngày về!",
                    },
                  ]}
                  name="returnDate"
                  className="w-full mb-4"
                >
                  <DatePicker
                    className="w-full h-12"
                    placeholder="Chọn ngày"
                    format="DD/MM/YYYY"
                    minDate={dayjs()}
                    allowClear={false}
                    locale={locale} // Thiết lập locale tiếng Việt
                    suffixIcon={
                      <img
                        src={"/images/date.svg"}
                        className="w-[14px]"
                        alt=""
                      />
                    }
                    open={false}
                    onOpenChange={() => onOpenChange("returnDate")}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
        </div>
      </Form>
      <Button
        type="primary"
        className="w-full h-12 my-4"
        style={{
          backgroundColor: "#0064D2",
          borderColor: "#0064D2",
          color: "#FFFFFF",
          fontWeight: "bold",
        }}
        onClick={() => {
          formRef.current.submit();
        }}
      >
        <FontAwesomeIcon icon={faSearch} color="white" />
        <span className="text-[16px] font-semibold">Tìm chuyến</span>
      </Button>
      <div className="text-center my-4">
        <span className="text-[#808080] font-normal">
          Hotline hỗ trợ khách hàng{" "}
        </span>
        <a
          href="tel:0876100800"
          className="font-bold text-[#0064D2] text-[16px]"
        >
          0876.100.800
        </a>
      </div>
      {filterStatic && (
        <FilterBar
          filter={filter}
          setFilter={setFilter}
          handleFindRoute={handleFindRoute}
          filterStatic={filterStatic}
          resetFilter={resetFilter}
          setResetFilter={setResetFilter}
        />
      )}
      {listTrip && listTrip.length > 0 ? (
        <>
          <div className="mt-4 w-full">
            {listTrip.map((item, index) => (
              <CarItem
                key={index}
                setOpenChooseCar={setOpenChooseCar}
                data={item}
                setCarSelected={setCarSelected}
                addPrice={addPrice}
                area={area}
              />
            ))}
          </div>
          <Pagination
            current={filter.currentPage}
            className="my-4"
            total={totalTrip}
            onChange={(page) => {
              const _filter = { ...filter, currentPage: page };
              setFilter(_filter);
              handleFindRoute(_filter);
            }}
            pageSize={20}
            showSizeChanger={false}
            showQuickJumper={false}
          />
        </>
      ) : (
        <>
          {!loading && searched && (
            <div className="flex flex-col w-full items-center mt-[48px]">
              <div className="text-[24px] text-[#0064D2] font-bold text-center mb-3">
                Không tìm thấy chuyến trên Ipass
              </div>
              <div className="mb-4 text-center text-[#0064D2]">
                Hiện tại <span className="font-bold">Ipass</span> chưa cập nhật
                đơn hàng của tuyến{" "}
                <span className="font-bold">
                  {area?.from?.name} - {area?.to?.name} ngày{" "}
                  {dayjs(filter.departDate).format("DD-MM-YYYY")}
                </span>
              </div>
              <div className="mb-4 text-center text-[#0064D2]">
                Xin quý khách vui lòng tìm chuyến với ngày khởi hành khác hoặc
                thay đổi tuyến đường tìm kiếm.
              </div>
              {/* <img
            src="/images/route-no-schedule.png"
            alt=""
            srcset=""
            className="max-w-[100%]"
          /> */}
              <Empty description={""} />
            </div>
          )}
        </>
      )}
      {openChooseCar && (
        <ChooseCar
          carSelected={carSelected}
          open={openChooseCar}
          addPrice={addPrice}
          setOpenChooseCar={setOpenChooseCar}
          setLoading={setLoading}
          setCarSelected={setCarSelected}
          area={area}
          tripType={tripType}
          filterSearch={filter}
        />
      )}
      {openDrawerChooseAdd && (
        <div className="location">
          <Drawer
            title={
              <h1 className="text-lg font-bold text-white">
                Chọn điểm {nameField === "addStart" ? "đi" : "đến"}
              </h1>
            }
            placement="bottom"
            open={openDrawerChooseAdd}
            onClose={() => {
              setOpenDrawerChooseAdd(false);
            }}
            height="100vh"
          >
            <div className="custom-scroll pb-[64px]">
              <Input
                placeholder="Tìm kiếm địa điểm"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
                className="mb-4"
              />

              <div className="overflow-auto airport-list">
                {!!provinces.length && (
                  <>
                    <h4 className="mb-2 font-semibold text-md text-primary">
                      Tỉnh/Thành (trực thuộc Trung ương)
                    </h4>
                    <div className="flex gap-2.5 flex-wrap">
                      {provinces.map((item) => (
                        <div
                          key={item.id}
                          className="h-8 flex items-center rounded-lg px-2 bg-[#F5F8FC]"
                          onClick={() => handleSelectAdd(item.id)}
                        >
                          {item?.name}
                        </div>
                      ))}
                    </div>
                  </>
                )}

                {!!districts.length && (
                  <div className="mt-4">
                    <h4 className="mb-2 font-semibold text-md text-primary">
                      Quận/Huyện/Thị trấn/Thành phố (trực thuộc tỉnh)
                    </h4>
                    <div className="flex gap-2.5 flex-wrap">
                      {districts.map((item) => (
                        <div
                          key={item.id}
                          className="h-8 flex items-center rounded-lg px-2 bg-[#F5F8FC]"
                          onClick={() => handleSelectAdd(item.id)}
                        >
                          {item?.name}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Drawer>
        </div>
      )}
    </div>
  );
};

export default Booking;
