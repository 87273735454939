import {
  Button,
  Col,
  Form,
  Input,
  Space,
  notification,
  Checkbox,
  Drawer,
  Slider,
  Rate,
  Dropdown,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faSort,
  faArrowDown,
  faArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import "moment/locale/vi"; // Import locale tiếng Việt
import React, { useEffect, useState } from "react";
import $axios from "../../services/axios";
import _ from "lodash";

const FilterBar = ({
  setLoading,
  loading,
  filter,
  setFilter,
  handleFindRoute,
  filterStatic,
  resetFilter,
  setResetFilter,
}) => {
  const [openFilterTab, setOpenFilterTab] = useState(false);
  const [form] = Form.useForm();
  const [selectedRating, setSelectedRating] = useState(0);
  const [companies, setCompanies] = useState(
    filterStatic?.companies?.data || []
  );
  const [searchCompany, setSearchCompany] = useState("");
  const handleMenuClickSort = (e) => {
    const _filter = { ...filter, sort: e.key };
    setFilter(_filter);
    handleFindRoute(_filter);
  };

  const handleSortTime = () => {
    const _filter = {
      ...filter,
      sort: filter.sort === "time:asc" ? "time:desc" : "time:asc",
    };
    setFilter(_filter);
    handleFindRoute(_filter);
  };

  const handleSortFare = () => {
    const _filter = {
      ...filter,
      sort: filter.sort === "fare:asc" ? "fare:desc" : "fare:asc",
    };
    setFilter(_filter);
    handleFindRoute(_filter);
  };

  useEffect(() => {
    if (filterStatic) {
      setCompanies(filterStatic?.companies?.data);
    }
  }, [filterStatic]);

  useEffect(() => {
    if (resetFilter) {
      setSelectedRating(0);
      form.resetFields();
      setResetFilter(false);
    }
  }, [resetFilter]);

  const menuSortTime = {
    items: [
      {
        label: "Tăng dần",
        key: "time:asc",
      },
      {
        label: "Giảm dần",
        key: "time:desc",
      },
    ],
    onClick: handleMenuClickSort,
  };

  const menuSortFare = {
    items: [
      {
        label: "Tăng dần",
        key: "fare:asc",
      },
      {
        label: "Giảm dần",
        key: "fare:desc",
      },
    ],
    onClick: handleMenuClickSort,
  };

  const handleCheckboxChange = (value) => {
    setSelectedRating((prevValue) => (prevValue === value ? 0 : value));
    form.setFieldValue("ratingMin", value);
  };

  // Hàm chuyển đổi phút thành giờ:phút
  const formatTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${String(hours).padStart(2, "0")}:${String(mins).padStart(2, "0")}`;
  };

  const formatPrice = (price) => {
    return `${price.toLocaleString("vi-VN")}`;
  };

  const onClose = () => {
    setOpenFilterTab(false);
    setSearchCompany("");
    setCompanies(filterStatic.companies.data);
  };

  const chooseFilterTab = (values) => {
    let _filter = {
      ...filter,
      isPromotion: values?.isPromotion ? 1 : 0,
      limousine: values?.limousine ? 1 : 0,
      ratingMin: values?.ratingMin ? values?.ratingMin : 0,
      timeMin: values.rangeTime ? formatTime(values.rangeTime[0]) : "00:00",
      timeMax: values.rangeTime ? formatTime(values.rangeTime[1]) : "23:59",
      fareMin: values.rangePrice ? values.rangePrice[0] : 0,
      fareMax: values.rangePrice ? values.rangePrice[1] : 2000000,
      companies: values?.companies || [],
    };
    setFilter(_filter);
    handleFindRoute(_filter);
    onClose();
  };

  return (
    <div
      className="bg-white p-4 text-center items-center flex mt-4 rounded-[12px] w-full gap-3"
      style={{
        boxShadow: "0px 4px 4px 4px rgba(0, 0, 0, 0.25)",
        justifyContent: filterStatic ? "space-between" : "space-around",
      }}
    >
      <Button
        className="bg-[#0064D2] text-white focus:bg-[#0064D2] hover:bg-[#0064D2]"
        onClick={() => setOpenFilterTab(true)}
        type="primary"
      >
        <FontAwesomeIcon icon={faFilter} color="white" />
        Bộ Lọc
      </Button>

      {/* <Dropdown menu={menuSortTime}> */}
      <Button
        className="bg-[#0064D2] text-white focus:bg-[#0064D2] hover:bg-[#0064D2]"
        onClick={handleSortTime}
        type="primary"
      >
        <Space>
          Giờ đi
          {filter.sort === "time:desc" && (
            <FontAwesomeIcon icon={faArrowDown} color="white" />
          )}
          {filter.sort === "time:asc" && (
            <FontAwesomeIcon icon={faArrowUp} color="white" />
          )}
        </Space>
      </Button>
      {/* </Dropdown> */}

      {/* <Dropdown menu={menuSortFare}> */}
      <Button
        className="bg-[#0064D2] text-white focus:bg-[#0064D2] hover:bg-[#0064D2]"
        onClick={handleSortFare}
        type="primary"
      >
        <Space>
          Mức giá
          {filter.sort === "fare:desc" && (
            <FontAwesomeIcon icon={faArrowDown} color="white" />
          )}
          {filter.sort === "fare:asc" && (
            <FontAwesomeIcon icon={faArrowUp} color="white" />
          )}
        </Space>
      </Button>
      {/* </Dropdown> */}

      {filterStatic && (
        <Drawer
          title="Danh sách lọc"
          placement={"left"}
          closable={true}
          onClose={onClose}
          open={openFilterTab}
          width="100%"
        >
          <Form
            form={form}
            onFinish={chooseFilterTab}
            layout="horizontal"
            className="m-0"
            initialValues={{
              limousine: null,
              isPromotion: null,
            }}
          >
            <div className="mb-2 font-semibold">Tiêu chí phổ biến</div>
            <Form.Item
              name="isPromotion"
              className="w-full mb-0"
              valuePropName="checked"
            >
              <Checkbox
                size="large"
                disabled={
                  !filterStatic.is_only_promotion ||
                  filterStatic.is_only_promotion === 0
                }
              >
                Chuyến giảm giá ({filterStatic.is_only_promotion})
              </Checkbox>
            </Form.Item>
            <Form.Item
              name="limousine"
              className="w-full mb-0"
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  !_.some(
                    filterStatic.vehicle_types,
                    (v) => v.value === "LIMOUSINE" && v.count > 0
                  )
                }
                size="large"
              >
                Xe VIP Limousine (
                {_.get(
                  _.find(
                    filterStatic?.vehicle_types,
                    (v) => v.value === "LIMOUSINE" && v.count > 0
                  ),
                  "count",
                  0
                )}
                )
              </Checkbox>
            </Form.Item>
            <div className="mt-6 mb-0 font-semibold">Giờ đi</div>
            <Form.Item name="rangeTime" className="w-full mb-0">
              <Slider
                min={0}
                range={true}
                max={1439}
                step={60}
                onChange={(value) => form.setFieldsValue({ startTime: value })}
                tooltip={{ formatter: formatTime }}
                marks={{
                  0: "00:00", // Nhãn đầu tiên
                  1439: "23:59", // Nhãn cuối cùng
                }}
              />
            </Form.Item>
            <div className="mt-6 mb-0 font-semibold">Giá đơn hàng</div>
            <Form.Item name="rangePrice" className="w-full mb-0">
              <Slider
                min={0}
                max={2000000}
                step={100000}
                range={true}
                tooltip={{ formatter: formatPrice }}
                onChange={(value) =>
                  form.setFieldsValue({ priceSelected: value })
                }
                marks={{
                  0: "0", // Nhãn đầu tiên
                  2000000: "2.000.000", // Nhãn cuối cùng
                }}
              />
            </Form.Item>
            <div className="mt-6 mb-2 font-semibold">Nhà xe</div>
            <Input
              className="w-full mb-4"
              value={searchCompany}
              onChange={(e) => {
                setSearchCompany(e.target.value);
                setCompanies(
                  filterStatic.companies.data.filter((c) =>
                    _.includes(_.toLower(c.name), _.toLower(e.target.value))
                  )
                );
              }}
            />
            <Form.Item name="companies" className="w-full mb-0">
              <Checkbox.Group className="w-full max-h-[200px] overflow-y-auto">
                {companies.map((c) => (
                  <Col span={24} className="mb-1" key={c.id}>
                    <Checkbox value={c.id}>{c.name}</Checkbox>
                  </Col>
                ))}
              </Checkbox.Group>
            </Form.Item>
            <div className="mt-6 mb-2 font-semibold">Đánh giá</div>
            <Form.Item name="ratingMin" className="w-full mb-0">
              {[4, 3, 2, 1].map((star) => (
                <Col span={24} className="mb-1" key={star}>
                  <Checkbox
                    value={star}
                    checked={selectedRating === star}
                    onChange={() => handleCheckboxChange(star)}
                  >
                    <div className="flex items-center justify-center gap-2">
                      <Rate
                        style={{ display: "flex" }}
                        disabled={true}
                        value={star}
                      />
                      <span>trở lên</span>
                    </div>
                  </Checkbox>
                </Col>
              ))}
            </Form.Item>
            <div className="flex items-center justify-center gap-4 py-4 mt-6">
              <Button
                type="primary"
                htmlType="sumit"
                className=" bg-[#0064D2]"
                loading={loading}
              >
                <span className="text-[16px] font-semibold">Lọc</span>
              </Button>
              <Button
                color="default"
                onClick={() => {
                  const _filter = {
                    ...filter,
                    fareMin: 0,
                    fareMax: 2000000,
                    ratingMin: 0,
                    ratingMax: 5,
                    timeMin: "00:00",
                    timeMax: "23:59",
                    limousine: null,
                    isPromotion: null,
                    companies: [],
                  };
                  setFilter(_filter);
                  handleFindRoute(_filter);
                  setSelectedRating(0);
                  form.resetFields();
                  onClose();
                }}
                variant="outlined"
                className="border-[#0064D2]"
              >
                <span className="text-[16px] font-semibold text-[#0064D2]">
                  Xoá đã chọn
                </span>
              </Button>
            </div>
          </Form>
        </Drawer>
      )}
    </div>
  );
};

export default FilterBar;
