import {
  Button,
  Col,
  Input,
  Row,
  Segmented,
  notification,
  Divider,
  Checkbox,
  Drawer,
  Rate,
  Radio,
  Tabs,
  Carousel,
  Progress,
  List,
  Skeleton,
  Avatar,
  Modal,
  Spin,
  Collapse,
  Popover,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleDown,
  faMapMarked,
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import "moment/locale/vi"; // Import locale tiếng Việt
import React, { useEffect, useState, createContext } from "react";
import $axios from "../../services/axios";
import BookingJSON from "../../data-test/booking.json";
import ConfigDetailJSON from "../../data-test/configDetail.json";
import { IconSeat1, IconSeat2, IconSeat1Disabled } from "./Icon";
import moment from "moment";
import _, { forEach } from "lodash";
const ReachableContext = createContext(null);

const { TabPane } = Tabs;
const InfoCar = ({
  carSelected,
  addPrice = 0,
  bookingField, //NOTE: start or end
  setBookingInfo,
  bookingInfo,
  handleOkNotValidTrip,
  openCarInfo,
  setOpenCarInfo,
  setLoading,
  loading,
  setInfoTripList,
  infoTripList,
  activeTabCarInfo,
  setActiveTabCarInfo,
}) => {
  const [typeAdd, setTypeAdd] = useState("addStart");
  const [slidesCarSelected, setSlidesCarSelected] = useState([]);
  const [policyCar, setPolicyCar] = useState(null);
  const [reviewCar, setReviewCar] = useState([]);
  const [totalReview, setTotalReview] = useState(0);
  const [infoTrip, setInfoTrip] = useState(null);
  const [currentPageReview, setCurrentPageReview] = useState(1);
  const [slideIndex, setSlideIndex] = useState(0);
  const [seatMap, setSeatMap] = useState([]);
  const [seatOptions, setSeatOptions] = useState([]);
  const [seatType, setSeatType] = useState(1);
  const [ratingCar, setRatingCar] = useState(null);
  const [configDetail, setConfigDetail] = useState([]);
  const [addChoosed, setAddChoosed] = useState(
    bookingInfo[bookingField]?.addChoosed
      ? bookingInfo[bookingField]?.addChoosed
      : {
          start: "",
          end: "",
        }
  );
  const [seatChoosed, setSeatChoosed] = useState(
    bookingInfo[bookingField]?.seatChoosed
      ? bookingInfo[bookingField]?.seatChoosed
      : []
  );
  const [totalSurcharge, setTotalSurcharge] = useState(
    bookingInfo[bookingField]?.totalSurcharge
      ? bookingInfo[bookingField]?.totalSurcharge
      : 0
  );
  const [totalNetPrice, setTotalNetPrice] = useState(
    bookingInfo[bookingField]?.totalNetPrice
      ? bookingInfo[bookingField]?.totalNetPrice
      : 0
  );
  const [modal, contextHolder] = Modal.useModal();
  const perPageReview = 10;

  useEffect(() => {
    if (carSelected) {
      getSlideCar();
      getReviewCar();
      getPolicyCar();
      getRatingCar();
      getInfoTrip();
    }
  }, [carSelected]);

  useEffect(() => {
    if (infoTrip) {
      handleCalculatePrice();
    }
  }, [addChoosed, seatChoosed, infoTrip]);

  const getSlideCar = async () => {
    try {
      const res = await $axios.$get(
        `/vexe/company/image?id=${carSelected?.company?.id}`
      );
      setSlidesCarSelected(res.data.data);
    } catch (error) {
    } finally {
    }
  };

  const getConfigDetail = async (trip_code, seat_template_id) => {
    try {
      const res = await $axios.$get(
        `/vexe/company_policy/config_detail?tripCode=${trip_code}&seatTemplateId=${seat_template_id}`
      );
      setConfigDetail(res.data.data);
    } catch (error) {
      setConfigDetail([]);
    } finally {
    }
  };

  const getInfoTrip = async () => {
    setLoading(true);
    try {
      const res = await $axios.$get(
        `/vexe/seat_map?tripCode=${carSelected?.route?.schedules[0]?.trip_code}`
      );
      const _infoTrip = _.get(res, "data.data", null);
      await getConfigDetail(
        carSelected?.route?.schedules[0]?.trip_code,
        _infoTrip.seat_template_id
      );
      if (_infoTrip) {
        setInfoTrip(_infoTrip);
        setInfoTripList({
          ...infoTripList,
          [bookingField]: _infoTrip,
        });
        // TODO: Đặt điểm đi điểm đến là giá trị đầu tiên
        if (!bookingInfo[bookingField]?.addChoosed) {
          const pointsStart = [
            ..._infoTrip?.pickup_points,
            ..._infoTrip?.transfer_points.map((point) => {
              point.is_transfer = true;
              return point;
            }),
          ];
          const pointsEnd = [
            ..._infoTrip?.drop_off_points_at_arrive,
            ..._infoTrip?.transfer_points_at_arrive.map((point) => {
              point.is_transfer = true;
              return point;
            }),
          ];
          setAddChoosed({
            start: _.find(
              pointsStart,
              (p) => !p.min_customer || p.min_customer === 1
            ),
            end: _.find(
              pointsEnd,
              (p) => !p.min_customer || p.min_customer === 1
            ),
          });
        }
        const coach_seat_template = _.map(
          _infoTrip?.coach_seat_template,
          (temp) => {
            _.forEach(temp.seats, (s) => {
              s.id = `${temp.coach_num}_${s.row_num}_${s.col_num}`; // các chỗ ngồi có id dạng tầng_cột_dòng  //floor_row_col
              s.newFare = s.fare + addPrice;
            });
            return temp;
          }
        );
        const _seatType = _.get(
          coach_seat_template,
          "[0].seats[0].seat_type",
          1
        );
        const _seatOptions = [];
        _.forEach(coach_seat_template, (temp) => {
          _.forEach(temp.seats, (s) => {
            if (!_.some(_seatOptions, (op) => op.seat_color === s.seat_color)) {
              _seatOptions.push({
                seat_color: s.seat_color,
                fare: s.newFare,
                name: s.seat_group,
              });
            }
          });
        });

        setSeatOptions(_.orderBy(_seatOptions, ["fare"], ["asc"]));
        if (_seatType === 2) {
          _.forEach(coach_seat_template, (temp) => {
            _.forEach(temp.seats, (s) => {
              if (
                !s.seat_color ||
                s.seat_color === "#000000" ||
                _seatOptions.length === 1
              ) {
                s.seat_color = s.is_available ? "#fff" : "#b2b2b2";
              }
            });
          });
        }

        setSeatMap(_.reverse(coach_seat_template));
        setSeatType(_seatType);

        if (_.get(carSelected, "route.schedules[0].unchoosable", 0)) {
          const instance = modal.confirm({
            title: "Việc sắp xếp khách khi lên xe sẽ do nhà xe quyết định!",
            maskClosable: false,
            closable: false,
            footer: [
              <div className="w-full text-center">
                <Button
                  key="ok"
                  type="primary"
                  onClick={() => instance.destroy()}
                  className="bg-[#0064D2] text-white"
                >
                  Đồng ý
                </Button>
              </div>,
            ],
            content: (
              <>
                <ReachableContext.Consumer>
                  {() => (
                    <div className="text-[14px] text-[#0D1634] font-medium">
                      Chuyến xe này nhà xe không hỗ trợ chọn trước chỗ ngồi, quý
                      khách vui lòng ra sớm để được sắp xếp chỗ ngồi tốt hơn.
                    </div>
                  )}
                </ReachableContext.Consumer>
                <br />
              </>
            ),
          });
        }
      }
    } catch (error) {
      console.log(error, "error");
      const instance = modal.error({
        title: "Dữ liệu về chuyến đi đang được cập nhật!",
        maskClosable: false,
        closable: false,
        footer: [
          <div className="w-full text-center">
            <Button
              key="ok"
              type="primary"
              onClick={() => {
                instance.destroy();
                handleOkNotValidTrip();
              }}
              className="bg-[#0064D2] text-white"
            >
              Đồng ý
            </Button>
          </div>,
        ],
        content: (
          <>
            <ReachableContext.Consumer>
              {() => (
                <div className="text-[14px] text-[#0D1634] font-medium">
                  Vui lòng chọn chuyến khác
                </div>
              )}
            </ReachableContext.Consumer>
            <br />
          </>
        ),
      });
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 0);
    }
  };

  const handleChooseSeat = (seat) => {
    const hasSeat = _.some(seatChoosed, (s) => s.id === seat.id);
    if (hasSeat) {
      const _seatChoosed = _.filter(seatChoosed, (s) => s.id !== seat.id);
      setSeatChoosed(_seatChoosed);
      if (addChoosed.start.min_customer > _seatChoosed.length) {
        setAddChoosed({
          ...addChoosed,
          start: "",
        });
      }
      if (addChoosed.end.min_customer > _seatChoosed.length) {
        setAddChoosed({
          ...addChoosed,
          end: "",
        });
      }
    } else {
      if (seatChoosed.length === infoTrip.max_total_seats) {
        notification.error({
          description: `Quý khách chỉ được chọn tối đa ${infoTrip.max_total_seats} khách cho mỗi lần đặt. Mọi thắc mắc vui lòng liên hệ hotline của nhà xe`,
        });
        return;
      }
      setSeatChoosed((prev) => [...prev, seat]);
    }
  };

  const getPolicyCar = async () => {
    try {
      const res = await $axios.$get(
        `/vexe/cancellation/policy?tripCode=${carSelected?.route?.schedules[0]?.trip_code}`
      );
      setPolicyCar(res.data.data);
    } catch (error) {
    } finally {
    }
  };

  const getRatingCar = async () => {
    try {
      const res = await $axios.$get(
        `/vexe/company/rating?id=${carSelected?.company?.id}`
      );
      setRatingCar(res.data.data);
    } catch (error) {
    } finally {
    }
  };

  const getReviewCar = async () => {
    try {
      const res = await $axios.$get(
        `/vexe/company/reviews?id=${carSelected?.company?.id}&page=${currentPageReview}&per_page=${perPageReview}`
      );
      setReviewCar(res.data.data.items);
      setTotalReview(res.data.data.total);
    } catch (error) {
    } finally {
    }
  };

  const onLoadMore = async () => {
    setReviewCar(
      reviewCar.concat(
        [...new Array(perPageReview)].map(() => ({
          loading: true,
          name: {},
          picture: {},
        }))
      )
    );
    setCurrentPageReview(currentPageReview + 1);
    const res = await $axios.$get(
      `/vexe/company/reviews?id=${carSelected?.company?.id}&page=${
        currentPageReview + 1
      }&per_page=${perPageReview}`
    );
    const newData = reviewCar.concat(res.data.data.items);
    setReviewCar(newData);
    window.dispatchEvent(new Event("resize"));
  };

  const handleCalculatePrice = () => {
    let _totalNetPrice = 0;
    let _totalSur = 0;
    if (seatChoosed.length > 0) {
      _totalNetPrice = _.sum(_.map(seatChoosed, (s) => s.newFare));
      if (addChoosed.start || addChoosed.end) {
        // surcharge_type === 1 => Nhà xe thu => Không cộng tiền
        if (
          addChoosed?.start?.surcharge > 0 &&
          addChoosed?.start.surcharge_type !== 1
        ) {
          let surchargeTiers = null;
          if (addChoosed?.start?.surcharge_tiers) {
            surchargeTiers = JSON.parse(addChoosed?.start?.surcharge_tiers);
            _.forEach(surchargeTiers, (sur) => {
              if (
                _.gte(seatChoosed.length, _.toNumber(sur.from)) &&
                (_.lte(seatChoosed.length, _.toNumber(sur.to)) || !sur.to)
              ) {
                if (sur.unit === "ticket") {
                  _totalSur += seatChoosed.length * _.toNumber(sur.surcharge);
                } else if (sur.unit === "group") {
                  _totalSur += _.toNumber(sur.surcharge);
                }
                // thoát khỏi vòng lặp
                return false;
              }
            });
          } else {
            _totalSur += seatChoosed.length * addChoosed.start.surcharge;
          }
        }
        if (
          addChoosed?.end?.surcharge > 0 &&
          addChoosed?.end.surcharge_type !== 1
        ) {
          let surchargeTiers = null;
          if (addChoosed?.end?.surcharge_tiers) {
            surchargeTiers = JSON.parse(addChoosed?.end?.surcharge_tiers);
            _.forEach(surchargeTiers, (sur) => {
              if (
                _.gte(seatChoosed.length, _.toNumber(sur.from)) &&
                (_.lte(seatChoosed.length, _.toNumber(sur.to)) || !sur.to)
              ) {
                if (sur.unit === "ticket") {
                  _totalSur += seatChoosed.length * _.toNumber(sur.surcharge);
                } else if (sur.unit === "group") {
                  _totalSur += _.toNumber(sur.surcharge);
                }
                // thoát khỏi vòng lặp
                return false;
              }
            });
          } else {
            _totalSur += seatChoosed.length * addChoosed.end.surcharge;
          }
        }
      }
    }
    setTotalSurcharge(_totalSur);
    setTotalNetPrice(_totalNetPrice);
    setBookingInfo({
      ...bookingInfo,
      [bookingField]: {
        addChoosed,
        seatChoosed,
        totalNetPrice: _totalNetPrice,
        totalSurcharge: _totalSur,
        totalPrice: _.sum([
          _totalNetPrice,
          _totalSur,
          infoTrip.company_id === 11071 ? infoTrip.service_fee : 0,
        ]),
      },
    });
  };

  const getSeatInfo = (seatTemp, seatId) => {
    return seatTemp.seats.find((s) => s.id === seatId);
  };

  const openNoteModal = (note) => {
    const instance = modal.info({
      title: "Lưu ý",
      maskClosable: false,
      closable: false,
      footer: [
        <div className="w-full text-center">
          <Button
            key="ok"
            type="primary"
            onClick={() => {
              instance.destroy();
            }}
            className="bg-[#0064D2] text-white"
          >
            Đóng
          </Button>
        </div>,
      ],
      content: (
        <>
          <ReachableContext.Consumer>
            {() => (
              <div className="text-[14px] text-[#0D1634] font-medium">
                {note}
              </div>
            )}
          </ReachableContext.Consumer>
          <br />
        </>
      ),
    });
  };

  const loadMore =
    totalReview > reviewCar.length ? (
      <div
        style={{
          textAlign: "center",
          marginTop: 12,
          height: 32,
          lineHeight: "32px",
        }}
      >
        <Button onClick={onLoadMore} className="bg-[#0064d2] text-white">
          Xem thêm đánh giá
        </Button>
      </div>
    ) : null;

  return (
    <Spin tip="Tải dữ liệu" size="large" spinning={loading}>
      <div className="flex justify-between mb-3">
        <Checkbox size="large" onClick={(e) => e.preventDefault()}>
          <span className="text-[12px]">Còn trống</span>
        </Checkbox>
        <Checkbox
          className="checkbox_booked"
          size="large"
          onClick={(e) => e.preventDefault()}
        >
          <span className="text-[12px]"> Đã đặt</span>
        </Checkbox>
        <Checkbox
          className="checkbox_selecting"
          size="large"
          onClick={(e) => e.preventDefault()}
        >
          <span className="text-[12px]">Đang chọn</span>
        </Checkbox>
      </div>
      {seatOptions.length > 1 && (
        <>
          <div className="text-[#484848] font-semibold text-[12px] mt-4">
            Chú thích
          </div>
          <div className="flex flex-row flex-wrap gap-4 mt-1 mb-3 w-full justify-center">
            {seatOptions.map((opt) => (
              <div className="flex flex-col w-[40%]">
                <div className="text-center mb-1">{opt.name}</div>
                <div
                  key={opt.fare}
                  style={{
                    border: `1px solid ${opt.seat_color}`,
                  }}
                  className="w-full p-1 text-center rounded"
                >
                  <span> {opt.fare.toLocaleString("vi-VN")} đ</span>
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      <div className="p-2 bg-[#e7ffe5] text-[#7bc27b] rounded my-4 text-center">
        Vui lòng chọn ghế.
      </div>

      {seatMap.map((temp, index) => (
        <div
          className="sodoghe bg-[#f2f2f2] p-[8px] flex relative w-full"
          key={`temp_${index}`}
        >
          <div className="flex flex-col justify-end  w-[40px]">
            <div className="absolute bottom-[50%] left-0 text-sm -rotate-90">
              {temp?.coach_name}
            </div>
            {temp?.coach_num === 1 && (
              <img
                src={"/images/volang.svg"}
                alt=""
                className="w-[18px] h-[18px]"
              />
            )}
          </div>
          {/* chú ý có cục seat-template__detail-fare với seattype =1 */}
          {/* row: cột , col => dòng */}
          <div className="flex-1">
            <table className="w-full mt-2">
              <tbody>
                {_.range(temp?.num_cols, 0).map((col) => (
                  <tr
                    id={`col_${col}`}
                    className="flex justify-between mb-1"
                    key={`col-${col}`}
                  >
                    {_.range(1, temp?.num_rows + 1).map((row) => (
                      <>
                        {_.some(
                          temp.seats,
                          (s) => s.id === `${temp.coach_num}_${row}_${col}`
                        ) ? (
                          <>
                            {_.some(
                              temp.seats,
                              (s) =>
                                s.id === `${temp.coach_num}_${row}_${col}` &&
                                s.is_available &&
                                !s.is_locked_seat
                            ) ? (
                              <td
                                className="w-[20px]"
                                key={`row_${row}`}
                                onClick={() => {
                                  handleChooseSeat(
                                    getSeatInfo(
                                      temp,
                                      `${temp.coach_num}_${row}_${col}`
                                    )
                                  );
                                }}
                              >
                                {seatType === 1 || seatType === 7 ? (
                                  <IconSeat1
                                    color={
                                      _.some(
                                        seatChoosed,
                                        (s) =>
                                          s.id ===
                                          `${temp.coach_num}_${row}_${col}`
                                      )
                                        ? "#ace543"
                                        : getSeatInfo(
                                            temp,
                                            `${temp.coach_num}_${row}_${col}`
                                          ).seat_color
                                    }
                                  />
                                ) : (
                                  <IconSeat2
                                    color={
                                      _.some(
                                        seatChoosed,
                                        (s) =>
                                          s.id ===
                                          `${temp.coach_num}_${row}_${col}`
                                      )
                                        ? "#ace543"
                                        : getSeatInfo(
                                            temp,
                                            `${temp.coach_num}_${row}_${col}`
                                          ).seat_color
                                    }
                                  />
                                )}
                              </td>
                            ) : (
                              <td key={`row_${row}`} className="w-[20px]">
                                {seatType === 1 || seatType === 7 ? (
                                  <IconSeat1Disabled />
                                ) : (
                                  <IconSeat2
                                    color="#b2b2b2"
                                    className="opacity-50"
                                  />
                                )}
                              </td>
                            )}
                          </>
                        ) : (
                          <td key={`row_${row}`} className="w-[20px]"></td>
                        )}
                      </>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ))}

      <Segmented
        onChange={(value) => {
          setTypeAdd(value);
        }}
        className="w-full my-[24px]"
        value={typeAdd}
        options={[
          {
            label: (
              <div className="w-[110px] flex items-center gap-2 p-2">
                <div>Điểm đi</div>
              </div>
            ),
            value: "addStart",
          },
          {
            label: (
              <div className="w-[110px] flex items-center gap-2 p-2">
                <span>Điểm đến</span>
              </div>
            ),
            value: "addEnd",
          },
        ]}
        block
      />

      {typeAdd === "addStart" && (
        <div className="flex flex-col gap-2 mb-3">
          <Radio.Group
            onChange={(e) => {
              const points = [
                ...infoTrip?.pickup_points,
                ...infoTrip?.transfer_points.map((point) => {
                  point.is_transfer = true;
                  return point;
                }),
              ];
              setAddChoosed({
                ...addChoosed,
                start: _.find(points, (p) => p.id === e.target.value),
              });
              // if (!addChoosed.end) {
              //   setTypeAdd("addEnd");
              // }
            }}
            value={addChoosed.start.id}
          >
            <div className="mb-4 font-semibold">Điểm đi</div>
            {infoTrip?.pickup_points.map((point) => (
              <>
                {point.is_vxr_display && (
                  <div className="flex flex-col">
                    <Radio
                      key={point.id}
                      disabled={
                        point.min_customer &&
                        point.min_customer > 1 &&
                        point.min_customer > seatChoosed.length
                      }
                      value={point.id}
                      className="self-start select-add-radio"
                    >
                      <div className="flex flex-col ">
                        <span>
                          {" "}
                          <span className="font-semibold">
                            {point?.real_time.split(" ")[0]}{" "}
                          </span>
                          - {point?.name}
                          {point?.surcharge > 0 && (
                            <span className="ml-2">
                              (Phụ thu {point.surcharge.toLocaleString("vi-VN")}
                              đ/ người{" "}
                              {point?.surcharge_type === 1 && (
                                <span>, Nhà xe thu</span>
                              )}
                              )
                            </span>
                          )}
                        </span>
                        {point.min_customer > 1 && (
                          <span className="flex gap-2">
                            Chỉ đón từ {point.min_customer} khách
                          </span>
                        )}
                        {point?.address && (
                          <span className="flex items-center gap-2 pl-3">
                            <FontAwesomeIcon icon={faMapMarked} />
                            {point?.address}
                          </span>
                        )}
                      </div>
                    </Radio>
                    {/* Pick up point is fixed or not fixed (1-Not fixed, 0-Fixed){" "} */}
                    {point.unfixed_point === 1 &&
                      addChoosed.start.id === point.id && (
                        <Input
                          className="ml-8 w-[80%] mt-1"
                          placeholder="Nhập địa chỉ trung chuyển"
                          value={addChoosed?.start?.transfer_point}
                          onChange={(e) => {
                            const _start = _.cloneDeep(addChoosed.start);
                            _start["transfer_point"] = e.target.value;
                            setAddChoosed({ ...addChoosed, start: _start });
                          }}
                        />
                      )}
                    {point.note && (
                      <div
                        className="underline text-[12px] ml-8 text-red-500"
                        onClick={() => openNoteModal(point.note)}
                      >
                        Lưu ý quan trọng
                      </div>
                    )}
                    <Divider
                      variant="dashed"
                      style={{ borderColor: "#0064D2" }}
                      className="my-4 py-0"
                      dashed
                    />
                  </div>
                )}
              </>
            ))}

            {infoTrip?.transfer_points.length > 0 && (
              <>
                <div className="mb-4 font-semibold">Chọn điểm trung chuyển</div>
                {infoTrip?.transfer_points.map((point) => (
                  <>
                    {point.is_vxr_display && (
                      <div className="flex flex-col">
                        <Radio
                          key={point.id}
                          disabled={
                            point.min_customer &&
                            point.min_customer > 1 &&
                            point.min_customer > seatChoosed.length
                          }
                          value={point.id}
                          className="self-start select-add-radio"
                        >
                          <div className="flex flex-col ">
                            <span>
                              {" "}
                              <span className="font-semibold">
                                {point?.real_time.split(" ")[0]}{" "}
                              </span>{" "}
                              - {point?.name}
                              {point?.surcharge > 0 && (
                                <span className="ml-2">
                                  (Phụ thu{" "}
                                  {point.surcharge.toLocaleString("vi-VN")}
                                  đ/ người{" "}
                                  {point?.surcharge_type === 1 && (
                                    <span>, Nhà xe thu</span>
                                  )}
                                  )
                                </span>
                              )}
                            </span>
                            {point.min_customer > 1 && (
                              <span className="flex gap-2">
                                Chỉ đón từ {point.min_customer} khách
                              </span>
                            )}

                            {point?.address && (
                              <span className="flex items-center gap-2 pl-3">
                                <FontAwesomeIcon icon={faMapMarked} />
                                {point?.address}
                              </span>
                            )}
                          </div>
                        </Radio>
                        {/* Unfixed_point: is that point fixed or not (0: unfixed, 1: fixed). Allow customer to enter the address only in case 1. */}
                        {point.unfixed_point === 1 &&
                          addChoosed.start.id === point.id && (
                            <Input
                              className="ml-8 w-[80%] mt-1"
                              placeholder="Nhập địa chỉ trung chuyển"
                              value={addChoosed?.start?.transfer_point}
                              onChange={(e) => {
                                const _start = _.cloneDeep(addChoosed.start);
                                _start["transfer_point"] = e.target.value;
                                setAddChoosed({
                                  ...addChoosed,
                                  start: _start,
                                });
                              }}
                            />
                          )}
                        {point.note && (
                          <div
                            className="underline text-[12px] ml-8 text-red-500"
                            onClick={() => openNoteModal(point.note)}
                          >
                            Lưu ý quan trọng
                          </div>
                        )}
                        <Divider
                          variant="dashed"
                          style={{ borderColor: "#0064D2" }}
                          className="my-4 py-0"
                          dashed
                        />
                      </div>
                    )}
                  </>
                ))}
              </>
            )}
          </Radio.Group>
        </div>
      )}
      {typeAdd === "addEnd" && (
        <div>
          <div className="flex flex-col gap-2">
            <Radio.Group
              onChange={(e) => {
                const points = [
                  ...infoTrip?.drop_off_points_at_arrive,
                  ...infoTrip?.transfer_points_at_arrive.map((point) => {
                    point.is_transfer = true;
                    return point;
                  }),
                ];
                setAddChoosed({
                  ...addChoosed,
                  end: _.find(points, (p) => p.id === e.target.value),
                });
              }}
              value={addChoosed.end.id}
            >
              <div className="mb-4 font-semibold">Điểm đến</div>
              {infoTrip?.drop_off_points_at_arrive.map((point) => (
                <>
                  {point.is_vxr_display && (
                    <div className="flex flex-col">
                      <Radio
                        key={point.id}
                        disabled={
                          point.min_customer &&
                          point.min_customer > 1 &&
                          point.min_customer > seatChoosed.length
                        }
                        value={point.id}
                        className="self-start select-add-radio"
                      >
                        <div className="flex flex-col ">
                          <span>
                            {" "}
                            <span className="font-semibold">
                              {point?.real_time.split(" ")[0]}{" "}
                            </span>
                            - {point?.name}
                            {point?.surcharge > 0 && (
                              <span className="ml-2">
                                (Phụ thu{" "}
                                {point.surcharge.toLocaleString("vi-VN")}
                                đ/người{" "}
                                {point?.surcharge_type === 1 && (
                                  <span>, Nhà xe thu</span>
                                )}
                                )
                              </span>
                            )}
                          </span>
                          {point.min_customer > 1 && (
                            <span className="flex gap-2">
                              Chỉ trả từ {point.min_customer} khách
                            </span>
                          )}

                          {point?.address && (
                            <span className="flex items-center gap-2 pl-3">
                              <FontAwesomeIcon icon={faMapMarked} />
                              {point?.address}
                            </span>
                          )}
                        </div>
                      </Radio>
                      {/* Unfixed_point: is that point fixed or not (0: unfixed, 1: fixed). Allow customer to enter the address only in case 1. */}
                      {point.unfixed_point === 1 &&
                        addChoosed.end.id === point.id && (
                          <Input
                            className="ml-8 w-[80%] mt-1"
                            placeholder="Nhập địa chỉ trung chuyển"
                            value={addChoosed?.end?.transfer_point}
                            onChange={(e) => {
                              const _end = _.cloneDeep(addChoosed.end);
                              _end["transfer_point"] = e.target.value;
                              setAddChoosed({ ...addChoosed, end: _end });
                            }}
                          />
                        )}
                      {point.note && (
                        <div
                          className="underline text-[12px] ml-8 text-red-500"
                          onClick={() => openNoteModal(point.note)}
                        >
                          Lưu ý quan trọng
                        </div>
                      )}
                      <Divider
                        variant="dashed"
                        style={{ borderColor: "#0064D2" }}
                        className="my-4 py-0"
                        dashed
                      />
                    </div>
                  )}
                </>
              ))}
              {infoTrip?.transfer_points_at_arrive.length > 0 && (
                <>
                  <div className="mb-4 font-semibold">
                    Chọn điểm trung chuyển
                  </div>
                  {infoTrip?.transfer_points_at_arrive.map((point) => (
                    <>
                      {point.is_vxr_display && (
                        <div className="flex flex-col">
                          <Radio
                            key={point.id}
                            disabled={
                              point.min_customer &&
                              point.min_customer > 1 &&
                              point.min_customer > seatChoosed.length
                            }
                            value={point.id}
                            className="self-start select-add-radio"
                          >
                            <div className="flex flex-col ">
                              <span>
                                {" "}
                                <span className="font-semibold">
                                  {point?.real_time.split(" ")[0]}{" "}
                                </span>
                                - {point?.name}
                                {point?.surcharge > 0 && (
                                  <span className="ml-2">
                                    (Phụ thu{" "}
                                    {point.surcharge.toLocaleString("vi-VN")}
                                    đ/ người{" "}
                                    {point?.surcharge_type === 1 && (
                                      <span>, Nhà xe thu</span>
                                    )}
                                    )
                                  </span>
                                )}
                              </span>
                              {point.min_customer > 1 && (
                                <span className="flex gap-2">
                                  Chỉ trả từ {point.min_customer} khách
                                </span>
                              )}
                              {point?.address && (
                                <span className="flex items-center gap-2 pl-3">
                                  <FontAwesomeIcon icon={faMapMarked} />
                                  {point?.address}
                                </span>
                              )}
                            </div>
                          </Radio>
                          {/* Unfixed_point: is that point fixed or not (0: unfixed, 1: fixed). Allow customer to enter the address only in case 1. */}
                          {point.unfixed_point === 1 &&
                            addChoosed.end.id === point.id && (
                              <Input
                                className="ml-8 w-[80%] mt-1"
                                placeholder="Nhập địa chỉ trung chuyển"
                                value={addChoosed?.end?.transfer_point}
                                onChange={(e) => {
                                  const _end = _.cloneDeep(addChoosed.end);
                                  _end["transfer_point"] = e.target.value;
                                  setAddChoosed({ ...addChoosed, end: _end });
                                }}
                              />
                            )}
                          {point.note && (
                            <div
                              className="underline text-[12px] ml-8 text-red-500"
                              onClick={() => openNoteModal(point.note)}
                            >
                              Lưu ý quan trọng
                            </div>
                          )}
                          <Divider
                            variant="dashed"
                            style={{ borderColor: "#0064D2" }}
                            className="my-4 py-0"
                            dashed
                          />
                        </div>
                      )}
                    </>
                  ))}
                </>
              )}
            </Radio.Group>
          </div>
        </div>
      )}

      <Drawer
        placement="bottom"
        onClose={() => setOpenCarInfo(false)}
        open={openCarInfo}
        mask={false}
        width={"100%"}
        height={"95%"}
        className="drawer-no-header"
      >
        <div className="flex items-center justify-center w-full">
          <FontAwesomeIcon
            icon={faAngleDoubleDown}
            color="#0064d2"
            className="w-full h-5"
            onClick={() => setOpenCarInfo(false)}
          />
        </div>
        <Tabs
          onChange={(e) => setActiveTabCarInfo(e)}
          className="w-full my-4"
          size="small"
          activeKey={activeTabCarInfo}
        >
          <TabPane tab="Hình ảnh" key="1" className="px-2">
            {slidesCarSelected.length > 0 && (
              <Carousel
                dots
                arrows
                infinite
                nextArrow={
                  <FontAwesomeIcon icon={faAngleDoubleRight} color="white" />
                }
                prevArrow={
                  <FontAwesomeIcon icon={faAngleDoubleLeft} color="white" />
                }
                beforeChange={(current, next) => setSlideIndex(next)} // manage slide index without passing to DOM
              >
                {slidesCarSelected.map((slide) => (
                  <img
                    key={slide.image_id}
                    src={`https:${slide?.files["1000x600"]}`}
                    className="w-full h-[216px] object-cover"
                    alt=""
                  />
                ))}
              </Carousel>
            )}
          </TabPane>
          <TabPane tab="Phí huỷ" key="2" className="px-4">
            {policyCar?.detail?.length > 0 &&
            _.get(carSelected, "route.schedules[0].refundable", 0) === 1 ? (
              <>
                <Row gutter={6} justify="center">
                  <Col span={8}>
                    <div className="font-semibold">Hủy từ</div>
                  </Col>
                  <Col span={8}>
                    <div className="font-semibold">Đến trước</div>
                  </Col>
                  <Col span={8}>
                    <div className="font-semibold">Phí hủy</div>
                  </Col>
                </Row>
                <Divider
                  variant="dashed"
                  dashed
                  className="my-4"
                  style={{ borderColor: "#0064d2" }}
                />

                {policyCar?.detail.map((item) => (
                  <>
                    {item.cancelable ? (
                      <Row align="middle" gutter={12} className="my-4">
                        <Col span={8}>
                          <div>
                            {item.from && item.to ? (
                              <span>
                                Từ {item.from} đến {item.to} tiếng
                              </span>
                            ) : (
                              <>
                                {item.from && !item.to ? (
                                  <span>
                                    {item.from === 0
                                      ? "Bất cứ lúc nào"
                                      : `Trước ${item.from} tiếng`}
                                  </span>
                                ) : (
                                  <span>Trong vòng {item.to} tiếng</span>
                                )}
                              </>
                            )}
                          </div>
                        </Col>
                        <Col span={8}>
                          <div>Giờ khởi hành</div>
                          <div>
                            {moment(policyCar?.trip_date).format(
                              "HH:mm DD/MM/YYYY"
                            )}
                          </div>
                        </Col>
                        <Col span={8}>
                          <div>
                            {item?.fee}
                            {item?.currency_description}
                            <br />
                            giá trị đơn hàng
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      <Row align="middle" gutter={12} className="my-4">
                        <Col span={24}>
                          <div>Vé không được phép huỷ</div>
                        </Col>
                      </Row>
                    )}
                  </>
                ))}

                <Divider
                  variant="dashed"
                  dashed
                  className="my-4"
                  style={{ borderColor: "#0064d2" }}
                />
                <Row gutter={6}>
                  <Col span={8}>
                    <div className="font-semibold">Ghi chú</div>
                  </Col>
                  <Col span={16}>
                    <div>{policyCar?.note}</div>
                  </Col>
                </Row>
              </>
            ) : (
              <div className="text-[14px] text-red-500 font-bold">
                Không hoàn huỷ mọi lúc
              </div>
            )}
          </TabPane>
          <TabPane tab="Đánh giá" key="3" className="px-2">
            <>
              {ratingCar && (
                <>
                  <div className="flex gap-4 mb-4">
                    <div className="text-[36px] font-semibold">
                      {ratingCar?.overall?.rv_main_value}
                    </div>
                    <div className="flex flex-col gap-2">
                      {ratingCar?.overall?.rv_main_value && (
                        <div className="flex items-center justify-center gap-2">
                          {ratingCar?.overall?.rv_main_value}
                          <Rate
                            style={{ display: "flex" }}
                            disabled={true}
                            value={ratingCar?.overall?.rv_main_value}
                          />
                        </div>
                      )}
                      {ratingCar?.overall?.total_reviews > 0 && (
                        <div>
                          {ratingCar?.overall?.total_reviews} lượt đánh giá
                        </div>
                      )}
                    </div>
                  </div>

                  {ratingCar?.rating?.map((item, index) => (
                    <div className="flex flex-col mb-2" key={index}>
                      <div className="text-[16px] mb-1">{item.label}</div>
                      <div className="flex gap-4">
                        <Progress
                          percent={(Number(item.rv_main_value) * 100) / 5}
                          showInfo={false}
                          className="h-6"
                        />
                        <div className="text-[#0064D2] text-[16px]">
                          {item.rv_main_value}
                        </div>
                      </div>
                    </div>
                  ))}
                  <List
                    className="loadmore-rating-list"
                    itemLayout="horizontal"
                    loadMore={loadMore}
                    dataSource={reviewCar}
                    renderItem={(item) => (
                      <List.Item>
                        <Skeleton
                          avatar
                          title={false}
                          loading={item.loading}
                          active
                        >
                          <List.Item.Meta
                            avatar={
                              <Avatar
                                src={
                                  item?.social_avatar
                                    ? item?.social_avatar
                                    : "/images/avatar-review.png"
                                }
                              />
                            }
                            title={
                              <div>
                                <div className="flex gap-2 text-[14px] items-center">
                                  <span>{item?.name || "Ẩn danh"}</span>{" "}
                                  {item?.trip_date && (
                                    <span className="text-[12px] text-[#00000073] self-end">
                                      -{" "}
                                      {moment(item?.trip_date).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </span>
                                  )}
                                </div>
                                {item?.trip_name && (
                                  <div className="text-[12px] text-[#00000073] self-end">
                                    {item?.trip_name}
                                  </div>
                                )}
                              </div>
                            }
                          />
                          <div>
                            <div className="flex items-center justify-center gap-2">
                              {item?.rating}
                              <Rate
                                style={{ display: "flex" }}
                                disabled={true}
                                value={Number(item?.rating)}
                              />
                            </div>
                          </div>
                        </Skeleton>
                      </List.Item>
                    )}
                  />
                </>
              )}
            </>
          </TabPane>
          <TabPane tab="Chính sách" key="4" className="px-2">
            <>
              {configDetail.length > 0 ? (
                <>
                  <Collapse
                    accordion
                    items={configDetail.map((conf, index) => {
                      return {
                        key: index,
                        label: conf.name,
                        children: (
                          <div>
                            {conf.details.map((detail) => (
                              <div className="mb-3">
                                <p className="font-medium mb-1">
                                  - {detail.title}
                                </p>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: detail.content,
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        ),
                      };
                    })}
                  />
                </>
              ) : (
                <div>Không có dữ liệu</div>
              )}
            </>
          </TabPane>
        </Tabs>
      </Drawer>
      {contextHolder}
    </Spin>
  );
};

export default InfoCar;
